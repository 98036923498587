<!--
 * @Author: lzj
 * @Date: 2022/2/18 13:37
 * @LastEditors: lzj
 * @LastEditTime: 2022/2/18 13:37
 * @Description: ....
-->
<template>
  <div class="qualification-list">
    <v-list
      ref="list"
      :isAdd="true"
      :isOperateColumn="false"
      :headers="qualificationTable"
      :tableUrl="getQualificationList"
      :searchParam="searchParam"
      :beforeList="getActivityList"
      :afterList="afterList"
    >
      <template #searchSlot>
        <v-select
          :options="activityList"
          v-model="searchParam.topicId"
          label="活动批次"
        />
        <area-select
          @changeModel="changeModel"
          specifyCollectionTypes="1"
          :disabled="true"
        />
        <v-select
          clearable
          :options="promiseSts"
          v-model="searchParam.isSignPromise"
          label="是否已签承诺书"
        />
        <v-select
          clearable
          :options="actAuditSts"
          v-model="searchParam.actScore"
          label="照片审核"
        />

        <v-select
          clearable
          :options="quScoreSts"
          v-model="searchParam.quScore"
          label="答题得分"
        />
        <v-select
          clearable
          :options="allScoreSts"
          v-model="searchParam.allScore"
          label="合格等级"
        />
        <div class="rate-wrapper">
          <div>
            完成率：
            {{
              calculation(listData.joinHouseCount, listData.allHouseCount, 3)
            }}%
          </div>
          <div>
            合格率：
            {{
              calculation(listData.qualifiedCount, listData.allHouseCount, 3)
            }}%
          </div>
        </div>
      </template>
    </v-list>

    <v-dialog
      title="详情"
      width="25%"
      v-model="actAuditStsDialogFormVisible"
      sureTxt="保存"
      @confirm="saveActDataAudit"
    >
      <el-form label-width="100px">
        <el-form-item label="照片:">
          <v-upload :limit="1" :imgUrls.sync="actImageUrls" disabled />
        </el-form-item>
        <el-form-item label="审核结果:">
          <v-select
            :options="allScoreSts"
            v-model="searchParam.dialogActAuditSts"
          />
        </el-form-item>
      </el-form>
    </v-dialog>

    <v-dialog
      title="查看"
      v-model="isSignPromiseDialogFormVisible"
      sureTxt="保存"
      @confirm="savePromise(houseId)"
    >
      <el-form label-width="100px">
        <el-form-item label="审核:">
          <v-select
            :options="newPromiseAuditSts"
            v-model="searchParam.dialogIsSignPromise"
          />
        </el-form-item>
        <el-form-item label="承诺书内容:">
          <v-upload :limit="1" :imgUrls.sync="signImageUrl" disabled />
        </el-form-item>
      </el-form>
    </v-dialog>
  </div>
</template>

<script>
import {
  getQualificationList,
  promiseAudit,
  postActDataAudit,
  getActivityList,
} from "./api";

import { createAlinkVNode, createHTMLVNode } from "@/utils/utils";
import {
  promiseAuditStsMap,
  promiseAuditSts,
  actAuditStsObjMap,
  actAuditSts,
  actScoreObjMap,
  villageList,
  promiseSts,
  quScoreSts,
  allScoreSts,
} from "./map.js";
export default {
  name: "qualificationList",
  data() {
    return {
      listData: {},
      allScoreSts,
      quScoreSts,
      actAuditSts,
      promiseSts,
      villageList,
      promiseAuditSts,
      activityList: [],
      promiseAuditStsMap,
      searchParam: {
        topicId: "",
        spaceIds: null,
        buildingData: null,
        isSignPromise: null,
        promiseAuditSts: null,
        actAuditSts: null,
        quScore: null,
        actScore: null,
        dialogActAuditSts: null,
        dialogIsSignPromise: null,
      },
      signImageUrl: "",
      actImageUrls: "",
      getQualificationList: getQualificationList,
      isSignPromiseDialogFormVisible: false,
      actAuditStsDialogFormVisible: false,
      actId: null,
      houseId: null,
      qualificationTable: [
        {
          prop: "villageName",
          label: "所属小区",
        },
        {
          prop: "communityName",
          label: "所属社区",
        },
        {
          prop: "building",
          label: "所属楼栋",
        },
        {
          prop: "houseName",
          label: "所属房号",
        },
        {
          prop: "userList",
          label: "房号下住户信息",
          formatter: (row) => {
            let oStr = "";
            if (row.userList) {
              row.userList.forEach((ele) => {
                oStr += `<p>${ele.userName}-${ele.mobileNum}</p>`;
              });
            }

            return createHTMLVNode(this, oStr, row, "", {
              cb: (e, oBtn, row, prop) => {},
            });
          },
        },
        {
          prop: "answerUserName",
          label: "答题人姓名",
        },
        {
          prop: "answerUserMobileNum",
          label: "答题人手机号",
        },
        {
          prop: "isSignPromise",
          label: "承诺书状态",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text:
                row.isSignPromise === 1
                  ? promiseAuditStsMap[row.promiseAuditSts]
                  : "未签",
              cb: () => {
                this.houseId = row.houseId;
                this.signImageUrl = row.signImageUrl;
                if (row.isSignPromise === 1 && row.promiseAuditSts == 0) {
                  this.isSignPromiseDialogFormVisible = true;
                }
              },
            }),
        },
        {
          prop: "quScore",
          label: "答题得分",
          formatter: (row) => {
            return actScoreObjMap[row.quScore];
          },
        },
        {
          prop: "actAuditSts",
          label: "照片审核",
          formatter: (row, prop) =>
            createAlinkVNode(this, row, prop, {
              text: actAuditStsObjMap[row.actAuditSts],
              cb: () => {
                this.actId = row.actId;
                this.actImageUrls = row.actImageUrls;
                if (row.actAuditSts === 0) {
                  this.actAuditStsDialogFormVisible = true;
                }
              },
            }),
        },
        {
          prop: "actScore",
          label: "合格等级",
          formatter: (row) => {
            if (row.quScore == -1 || row.actScore == -1) {
              return "不合格";
            } else if (
              row.quScore == 0 ||
              row.quScore == 1 ||
              row.actScore == 1 ||
              row.actScore == 0
            ) {
              return "不合格";
            } else if (row.quScore == 3 && row.actScore == 3) {
              return "合格";
            } else {
              return "基本合格";
            }
          },
        },
      ],
    };
  },
  computed: {
    newPromiseAuditSts() {
      return promiseAuditSts.slice(1);
    },
  },
  mounted() {
    var arr = [1, 2, 3];
    arr.splice(2);
    console.log(arr);
  },
  methods: {
    changeModel(value) {
      this.searchParam.spaceIds = value.spaceId;
    },
    afterList(data) {
      console.log(data, 987);
      this.listData = data;
    },
    async getActivityList() {
      let params = {
        curPage: 1,
        pageSize: 1000,
      };
      this.activityList = [];
      let res = await this.$axios.get(`${getActivityList}`, { params });
      if (res.code === 200) {
        res.data.records.forEach((item) => {
          let obj = {};
          obj.label = item.title;
          obj.value = item.id;
          this.activityList.push(obj);
        });
        this.searchParam.topicId = this.activityList[0].value;
      }
    },
    saveActDataAudit() {
      const params = {
        actId: this.actId,
        auditSts: +this.searchParam.dialogActAuditSts,
      };
      this.$axios({
        method: "post",
        url: postActDataAudit,
        params: params,
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功");
          this.actAuditStsDialogFormVisible = false;
          this.$refs.list.search();
        }
      });
    },
    savePromise(id) {
      this.$axios({
        method: "post",
        url: promiseAudit,
        params: {
          houseId: id,
          auditSts: this.searchParam.dialogIsSignPromise,
        },
      }).then((res) => {
        if (res.code === 200) {
          this.$message.success("保存成功");
          this.isSignPromiseDialogFormVisible = false;
          this.$refs.list.search();
        }
      });
    },

    calculation(dataOne, dataTwo, num) {
      if (dataOne != undefined && dataTwo != undefined) {
        return dataOne != "" || dataTwo != ""
          ? ((dataOne / dataTwo) * 100).toFixed(num)
          : "--";
      } else {
        return "--";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.qualification-list {
  box-sizing: border-box;
  height: 100%;
  .rate-wrapper {
    padding-top: 3px;
  }
}
</style>
